<template>
    <div class="row my-1 g-2 g-sm-3">
        <div class="col-sm-6" v-for="(file, fIndex) in files" :key="fIndex">
            <div class="card my-0 overflow-hidden shadow-none border text-left">
                <div class="" v-if="isImageFile(file.extension)">
                    <img @click="() => showImg(fIndex)" class="cursor-pointer img-fluid"
                    :src="absoluteUrl(file.path)" :alt="file.name">
                </div>
                <div v-else-if="file.type?.includes('audio')" class="pt-2 px-2">
                    <figure class="m-0">
                        <audio
                            class="audio-element"
                            :id="file.name"
                            controls
                            controlslist="nofullscreen nodownload noremoteplayback"
                            :src="absoluteUrl(file.path)">
                        </audio>
                    </figure>
                </div>
                <div class="p-2">
                    <div class="row g-1 g-sm-2 align-items-center">
                        <div class="col-auto">
                            <img style="width:30px;" :src="absoluteUrl(`/images/file-icons/${file.extension}.svg`)"/>
                        </div>
                        <div class="col">
                            <a :href="absoluteUrl(`/download?file_path=${file.path}`)"
                                :download="file.name" class="text-muted">
                                <div class="d-none mb-1 d-sm-block text-muted">{{file.name}}</div>
                                <div class="mb-0 d-flex align-items-center small"><i class="me-2 ri-download-2-fill"></i>{{file.size}}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "message-files",
    props: {
        files: {
            type: Array,
            required: true,
        },
    },
    methods:{
        showImg(imgIndex){
            this.$emit("showImg", {files: this.files, index: imgIndex})
        }
    }
}
</script>

<style scoped>
.audio-element{
    height: 40px;
}
@media(max-width:768px){
    .audio-element{
        width: 142px !important;
    }
}
</style>