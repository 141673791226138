
<template>
  <div>
     <vue-dropzone id="dropzone0111" ref="fileUploadDrop" 
     :use-custom-slot="true" :maxFiles="10">
      <div class="needsclick m-0">
        <div class="py-3">
          <h6>Drop files here or click to upload.</h6>
          <span class="text-muted small">
            (Each File should not be more than 10MB)
          </span>
        </div>
      </div>
    </vue-dropzone>
    <div class="mt-3">
      <b-button variant="primary" @click.prevent="sendAttachment()" type="button">Upload</b-button>
    </div>
</div>
</template>

<script>
import VueDropzone from "../VueDropzone.vue";

export default {
  components:{
    VueDropzone
  },
  name: "upload-files",
  props:{
    delivery: {
      default: false,
    }
  },
  methods: {
    sendAttachment() {
      if(!this.collectFiles().length){ this.alertError("Please a file is required"); return;}
      let formData = new FormData()
      this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/order-messages/${this.$route.params.orderId}/attachments${this.delivery ? '?delivery=1' : '' }`,formData)
      .then(response => {
        this.$store.dispatch("changeLoaderValue", false)
        if(response.data.success){
          this.$store.commit(`orderList/ADD_MESSAGE`, response.data.data)
          this.$emit("uploaded")
          this.clearUploader()
        }
     })
    },
    collectFiles(){
      let arrafile = [];
      if(this.$refs.fileUploadDrop){
        arrafile = [...this.$refs.fileUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    clearUploader(){
      if(this.$refs.fileUploadDrop){
        this.$refs.fileUploadDrop.removeAllFiles()
      }
    }
  }
}

</script>

