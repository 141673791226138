<template>
  <div class="order-overview">
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <button @click="$router.go(-1)" type="button" class="btn btn-dark"> Back</button> 
        </div>
      </div>
    </div>  
    <div v-if="isLoading" class="row">
      <div class="col-12">
        <is-loading />
      </div>
    </div>
    <div v-if="!isLoading" class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div v-if="order.status == 'draft'" >
              <b-alert :show="true"  variant="warning">
                <h5 class="alert-heading">Saved as draft!</h5>
                <p class="mb-0">
                  <span class="me-2">This order was saved as draft.</span>
                </p>
              </b-alert>
            </div>
            <div v-else>
              <div class="row mb-4">
                <div class="col-md-6 col-lg-4">
                  <div class="mb-2"> <span class="me-2">Order No: <strong>#{{order.number}}</strong></span> </div>
                  <div class="mb-2">Status: <span class="me-2" v-html="statusBadge(order.status)"></span>  </div>
                  <div class="mb-2">Total Price: <strong class="text-uppercase">${{$filters.money_format(order.amount)}}</strong> </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="mb-2">Product: <strong class="text-uppercase me-2">{{product.name}}</strong> </div>
                  <div class="mb-2">Date: <strong class="text-uppercase me-2">{{ $filters.date_time(order.created_at,1) }}</strong> </div>
                  <div ><a class="text-success" :href="absoluteUrl(`/${product.slug}`)">View this product</a> </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div v-if="order.cart.coupon" class="mb-2">Discount ({{order.cart.coupon.percent}}%): <strong class="text-uppercase">${{$filters.money_format(order.cart.coupon.discount)}}</strong> </div>
                  <div class="mb-2">Delivery date: <strong class="text-uppercase me-2">{{ $filters.deliveryDate(order.created_at, order.delivery_time)}}</strong> </div>
                  <div class="mb-2">Buyer details: 
                    <strong><router-link :to="`/users/${order.buyer_id}`" class="cursor-pointer">
                  <i class="mdi mdi-eye"></i> {{order.buyer.first_name }} {{order.buyer.last_name }}
                  </router-link></strong> </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <b-tabs active-tab-class="active" 
              nav-class="bg-white shadow nav-tabs-custom nav-primary mb-3">
              <b-tab :active="activeTab === 1">
                <template v-slot:title>
                  <i class="mdi mdi-book-outline me-1"></i>
                  <span class="nav-label">Brief</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <order-brief />
                  </div>
                </div>
              </b-tab>
              <b-tab :active="activeTab === 2" :lazy="true">
                <template v-slot:title>
                  <i class="mdi mdi-file-image me-1"></i>
                  <span class="nav-label">Conversations</span>
                </template>
                <div class="card">
                  <div class="card-body">
                    <order-messages />
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderBrief from "@/components/order/OrderBrief.vue"
import IsLoading from '@/components/IsLoading.vue'
import OrderMessages from '@/components/order/OrderMessages.vue'

export default {
  name: "order-overview",
  components: {
    OrderBrief,
    IsLoading,
    OrderMessages
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    '$route.params.orderId': function (id){
      if(id) this.fetchOrder()
    },
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    product(){
      return this.order.cart.product
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 2;
    },
  },
  methods:{
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        if(response.data.success){
          this.isLoading = false
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
  },
  created(){
    this.fetchOrder()
  },

}
</script>

<style lang="scss">

</style>
